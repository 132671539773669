<!--  -->
<template>
  <div id="view-video">
    <div class="main">
      <div class="query-form">
        <el-button type="primary" icon="el-icon-arrow-left">后退</el-button>
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <el-form-item label="时间:">
            <div class="flex-vertical-center">
              <el-date-picker
                type="date"
                placeholder=""
              ></el-date-picker>
              <span class="separate">~</span>
              <el-date-picker
                type="date"
                placeholder=""
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="video"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
    };
  },

  mounted() {},

  methods: {
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}
#view-video {
  width: 1621px;
  /* min-width: 1621px; */
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 22px 26px 26px 25px;
  box-sizing: border-box;
}
/* 查询表单 */
.query-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0 10px 0 0;
}
/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}
.form :deep() .el-select .el-input .el-input__inner {
  width: 176px;
  padding: 0 25px 0 8px;
}
.separate {
  margin: 0 4px;
}
.video{
  width: 1568px;
  height: 799px;
}
</style>
